import { useQuery } from '@tanstack/react-query';
import type { PiletApi, CurrentUserDTO } from '@piral/portal';

export function useCurrentUser(api: PiletApi): [boolean, CurrentUserDTO] {
  const { isLoading, data } = useQuery({
    queryKey: ['current-user'],
    queryFn: () => api.http.doQueryCurrentUser(),
    staleTime: Infinity,
  });
  return [isLoading, data];
}
