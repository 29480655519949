import { GlobalStateContext } from 'piral-core';
import type { CloudPortalState } from './types';

export function getPortalState<T>(ctx: GlobalStateContext, select: (state: CloudPortalState) => T): T {
  return ctx.readState((state) => select(state.portal));
}

export function setPortalState(ctx: GlobalStateContext, dispatch: (state: CloudPortalState) => CloudPortalState) {
  ctx.dispatch((state) => ({
    ...state,
    portal: dispatch(state.portal),
  }));
}
