import * as React from 'react';
import { DashboardContainer as Tiles } from '@smapiot/components';
import { usePiletApi } from 'piral-core';
import { DashboardContainerProps } from 'piral-dashboard';
import { useCurrentUser } from '@piral/portal-utils';
import { routes } from '../routes';

export const DashboardContainer: React.FC<DashboardContainerProps> = ({ children, history }) => {
  const api = usePiletApi();
  const [, user] = useCurrentUser(api);

  if (user instanceof Error) {
    history.push(routes.noauth);
    return null;
  }

  return (
    <div className="container dashboard-container">
      <Tiles>{children}</Tiles>
    </div>
  );
};
