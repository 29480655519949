import * as React from 'react';
import { hashKey } from '@tanstack/react-query';
import { SearchAsync } from './SearchAsync';
import { LoadMore } from './LoadMore';
import { usePiletApi } from '../hooks/usePiletApi';
import { ApiPaginatedData } from '../types';

function toEndpointPath(path: string, params: Record<string, string>) {
  const sym = path.indexOf('?') !== -1 ? '&' : '?';
  const query = Object.entries(params)
    .filter(([key, value]) => key && typeof value === 'string')
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return `${path}${sym}${query}`;
}

function defaultQuery(q: string, offset: string | undefined): Record<string, string> {
  return {
    q,
    offset,
  };
}

function isMatch(a: Array<any>, b: Array<any>) {
  if (a.length <= b.length) {
    const ak = hashKey(a);
    const bk = hashKey(b.slice(0, a.length));
    return ak === bk;
  }

  return false;
}

export interface FuzzySearchProps<T> {
  path: string;
  queryKey?: Array<any>;
  staleTime?: number;
  children(items: Array<T>, res: any): React.ReactNode;
  toQuery?(q: string, offset: string | undefined): Record<string, string>;
}

export function FuzzySearch<T>({
  path,
  children,
  queryKey = [path],
  staleTime,
  toQuery = defaultQuery,
}: FuzzySearchProps<T>) {
  const api = usePiletApi();
  const response = React.useRef({} as ApiPaginatedData<T>);
  const [v, setv] = React.useState(10000);

  React.useEffect(() => {
    const cache = api.client.getQueryCache();
    return cache.subscribe((ev) => {
      if (ev.type === 'updated' && ev.action.type === 'invalidate' && isMatch(queryKey, ev.query.queryKey)) {
        setv((v) => v + 1);
      }
    });
  }, queryKey);

  const performSearch = React.useCallback(
    async (query: string, offset: string, signal: AbortSignal): Promise<[Array<T>, string]> => {
      const params = toQuery(query, offset);
      const ep = toEndpointPath(path, params);

      try {
        const promise = api.client.fetchQuery({
          queryKey: [...queryKey, { query }, { offset }],
          queryFn: () => api.http.doGet<ApiPaginatedData<T>>(ep, { signal }),
          staleTime,
        });
        const { items, continuation } = (response.current = await promise);
        return [items, continuation];
      } catch {
        return [[], ''];
      }
    },
    [path],
  );

  return (
    <SearchAsync<T> onSearch={performSearch} key={v}>
      {(items, next, loading, hasOffset) => (
        <>
          {children(items, response.current)}
          <LoadMore onNext={next} loading={loading} canLoad={!!hasOffset} />
        </>
      )}
    </SearchAsync>
  );
}
