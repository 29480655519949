import * as actions from './actions';
import * as routes from '../routes';
import * as ws from '../ws';
import { http } from '../http';
import { PiralPlugin, useAction, useGlobalState, withKey, withoutKey, withApi } from 'piral-core';
import type { CloudPortalApi } from './types';
import type { GeneralData } from '../types';

export function createCloudPortalApi(data: GeneralData): PiralPlugin<CloudPortalApi> {
  return (ctx) => {
    const { queryClient, ...portal } = data;
    const apiBaseUrl = `${data.apiUrl}/api/v1`;
    const docsUrl = `${data.apiUrl}/api-docs`;

    ctx.client = queryClient;
    ctx.http = http;
    ctx.ws = ws;
    ctx.defineActions(actions);

    ctx.dispatch((state) => ({
      ...state,
      portal: {
        ...portal,
        paths: routes.routes,
        urls: {
          host: data.apiUrl,
          apiDocs: docsUrl,
          apiRoot: apiBaseUrl,
        },
      },
      registry: {
        ...state.registry,
        trackers: {},
        headerLinks: {},
      },
    }));

    return (api) => ({
      http,
      ws,
      client: queryClient,
      usePortalState(select) {
        return useGlobalState((state) => select(state.portal));
      },
      invokePortalAction(actionName, ...args) {
        return ctx[actionName]?.apply(ctx, args);
      },
      usePortalAction(actionName) {
        return useAction(actionName);
      },
      getPortalState(select) {
        return ctx.getPortalState(select) as any;
      },
      setPortalState(dispatch) {
        ctx.setPortalState(dispatch);
      },
      setPortalActions(actions) {
        ctx.defineActions(actions);
      },
      handleUpdate(eventType, handler, notify) {
        ws.addHandler(eventType, handler);

        if (notify) {
          api.on(`ws-notification-${eventType}`, notify);
        }
      },
      registerTracker(name, component) {
        ctx.dispatch((state) => ({
          ...state,
          registry: {
            ...state.registry,
            trackers: withKey(state.registry.trackers, name, withApi(ctx, component, api, 'extension', 'tracker')),
          },
        }));
      },
      unregisterTracker(name) {
        ctx.dispatch((state) => ({
          ...state,
          registry: {
            ...state.registry,
            trackers: withoutKey(state.registry.trackers, name),
          },
        }));
      },
      registerHeaderAction(name, settings) {
        ctx.dispatch((state) => ({
          ...state,
          registry: {
            ...state.registry,
            headerLinks: withKey(state.registry.headerLinks, name, settings),
          },
        }));
      },
      unregisterHeaderAction(name) {
        ctx.dispatch((state) => ({
          ...state,
          registry: {
            ...state.registry,
            headerLinks: withoutKey(state.registry.headerLinks, name),
          },
        }));
      },
    });
  };
}
