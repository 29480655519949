import * as React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Spinner } from '@smapiot/components';
import { PiralInstance } from 'piral-core';
import type { CurrentUserDTO } from '@piral/portal';

const UserApp = React.lazy(() => import('./UserApp'));
const AnonymousApp = React.lazy(() => import('./AnonymousApp'));

export interface AppProps {
  instance: PiralInstance;
  client: QueryClient;
  pages: Record<string, React.FC>;
  user: CurrentUserDTO | undefined;
}

export const App: React.FC<AppProps> = ({ instance, client, pages, user }) => (
  <React.Suspense fallback={<Spinner />}>
    <QueryClientProvider client={client}>
      {user ? <UserApp instance={instance} /> : <AnonymousApp instance={instance} pages={pages} />}
    </QueryClientProvider>
  </React.Suspense>
);
